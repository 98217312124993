<template>
  <div class="app">
    <NavComponent />
    <img src="@/assets/pic/logo3.png" alt="sale" class="logo" />
    <h1 class="medal_title">我的信息</h1>
    <h1 class="medal_title2">My Information</h1>

    <nav class="navbar">
      <ul>
        <li
          @click="currentPage = 'info'"
          :class="{ active: currentPage === 'info' }"
        >
          个人信息
        </li>
        <li
          @click="currentPage = 'records'"
          :class="{ active: currentPage === 'records' }"
        >
          兑换记录
        </li>
        <li
          @click="goToResults"
          :class="{ active: currentPage === 'messages' }"
        >
          投票结果
          <span v-if="hasNewResults" class="notification-dot"></span>
          <!-- 红点 -->
        </li>
      </ul>
    </nav>

    <div class="olympics-info" v-if="currentPage === 'info'">
      <h2>个人信息</h2>
      <p>昵称: {{ userInfo.nickname }}</p>
      <p>姓名: {{ userInfo.name }}</p>
      <p>手机号: {{ userInfo.phone }}</p>
      <p>详细地址: {{ userInfo.address }}</p>
      <p>积分: {{ userInfo.points }}</p>
    </div>

    <div class="olympics-info" v-if="currentPage === 'records'">
      <h2>兑换记录</h2>
      <ul class="exchange-records-list">
        <li v-for="(record, index) in exchangeRecords" :key="index">
          兑换了{{ record.name }} ，消耗积分：{{ record.points }}
        </li>
      </ul>
      <p v-if="!exchangeRecords.length">暂无兑换记录。</p>
    </div>

    <div class="olympics-info" v-if="currentPage === 'messages'">
      <h2>投票结果</h2>
      <table class="results-table">
        <thead>
          <tr>
            <th>比赛名称</th>
            <th>比赛结果</th>
            <th>所获积分</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="result in results" :key="result.competitionName">
            <td>{{ result.competitionName }}</td>
            <td>{{ result.isWin ? "胜" : "负" }}</td>
            <td>{{ result.isWin ? 10 : 0 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import NavComponent from "@/components/AppNav.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    NavComponent,
  },
  data() {
    return {
      currentPage: "info",
      hasNewMessages: true,
      hasNewResults: false,
      isReadButtonDisabled: false,
      userInfo: {
        phone: "",
        nickname: "",
        password: "",
        name: "",
        address: "",
        points: 0,
      },
      exchangeRecords: [], // 存储兑换记录
      results: [], // 存储投票结果
      ws: null, // WebSocket 实例
    };
  },
  created() {
    console.log(localStorage.getItem("token"));
    this.fetchUserInfo();
    this.fetchExchangeRecords();
    this.setupWebSocket();
  },
  methods: {
    async fetchUserInfo() {
      try {
        console.log(this.$baseUrl);
        const url = this.$baseUrl + "/user/user/profile";
        const token = localStorage.getItem("token");
        console.log("token:" + token);
        const response = await axios.get(url, {
          headers: {
            token: token,
          },
        });
        console.log(response.data);
        if (response.data.code === 200) {
          this.userInfo = response.data.data;
          console.log("User Info:", response.data.data);
        } else {
          console.error("Error fetching user info:", response.data.msg);
          this.userInfo = {
            phone: "",
            nickname: "",
            password: "",
            name: "",
            address: "",
            points: 0,
          }; // 确保有默认值
        }
      } catch (error) {
        console.error("API request failed:", error);
        this.userInfo = {
          phone: "",
          nickname: "",
          password: "",
          name: "",
          address: "",
          points: 0,
        }; // 确保有默认值
      }
    },
    async fetchExchangeRecords() {
      try {
        const url = this.$baseUrl + "/user/user/myExchangeRecord";
        const token = localStorage.getItem("token");
        const response = await axios.get(url, {
          headers: {
            token: token,
          },
        });
        if (response.data.code === 200) {
          this.exchangeRecords = response.data.data;
          console.log("Exchange Records:", response.data.data);
        } else {
          console.error("Error fetching exchange records:", response.data.msg);
        }
      } catch (error) {
        console.error("API request failed:", error);
      }
    },
    markMessagesAsRead() {
      this.hasNewMessages = false;
      this.isReadButtonDisabled = true;
    },
    goToResults() {
      this.currentPage = "messages";
      this.hasNewResults = false;
    },
    setupWebSocket() {
      this.ws = new WebSocket("ws://116.205.107.96:7770/ws/exchange");
      this.ws.onopen = () => {
        console.log("WebSocket connected");
        this.setFakeTime();
      };
      this.ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log(event.data);
        if (message.action === "results") {
          this.results = message.data;
          this.hasNewResults = true;
        }
      };
      this.ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
    },
    setFakeTime() {
      const fakeTime = this.$date;
      const timestamp = fakeTime.getTime();
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.ws.send(timestamp);
        console.log("send:", timestamp);
      } else {
        console.error("WebSocket is not open.");
      }
    },
  },
  beforeUnmount() {
    if (this.ws) {
      this.ws.close();
    }
  },
};
</script>

<style scoped>
.app {
  text-align: center;
}

.logo {
  display: inline-block;
  width: 100px;
  height: auto;
}

.medal_title {
  font-weight: 100;
  color: #7a5bb6;
}

.medal_title2 {
  font-weight: 100;
  color: #7a5bb6;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.olympics-info {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #fffffff2;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: justify;
  border-radius: 10px;
  margin-top: 20px;
}

.olympics-info h2 {
  text-align: left;
  font-size: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #1616169e;
  padding-bottom: 15px;
}

.olympics-info p {
  margin-bottom: 15px;
}

.navbar {
  background-color: #7a5bb6;
  padding: 10px 0;
  width: 60%;
  margin: 50px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 10;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.navbar li {
  margin: 0 15px;
  cursor: pointer;
  position: relative;
}

.navbar li.active {
  font-weight: bold;
}

.exchange-records-list {
  padding-left: 20px;
  list-style-type: disc;
}

.notification-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -10px;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.results-table th,
.results-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.results-table th {
  background-color: #7a5bb6;
}
</style>
