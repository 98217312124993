<template>
  <div class="rewards-list">
    <div class="reward-item" v-for="item in rewards" :key="item.id">
      <div class="reward-card">
        <img :src="item.url" alt="商品图片" class="reward-image" />
        <div class="reward-info">
          <h3>{{ item.name }}</h3>
          <p>积分：{{ item.points }}</p>
          <button @click="redeem(item)">兑换</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";

export default {
  name: "RewardsList",
  data() {
    return {
      rewards: [],
    };
  },
  created() {
    this.fetchRewards(); // 组件创建时获取奖品列表
  },
  methods: {
    async redeem(item) {
      try {
        const response = await axios.post(this.$baseUrl + "/user/prize", null, {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            prizeId: item.id, // 将prizeId作为查询参数传递
          },
        });
        if (response.data.code === 200) {
          ElMessageBox.alert("兑换成功！", "操作结果", {
            confirmButtonText: "确定",
            type: "success",
          });
        } else {
          ElMessageBox.alert("兑换失败：" + response.data.msg, "操作结果", {
            confirmButtonText: "确定",
            type: "error",
          });
        }
      } catch (error) {
        console.error("Error redeeming item:", error);
        ElMessageBox.alert(
          "兑换失败：网络错误或其他问题，请稍后再试。",
          "操作结果",
          {
            confirmButtonText: "确定",
            type: "error",
          }
        );
      }
    },
    async fetchRewards() {
      try {
        const response = await axios.get(this.$baseUrl + "/user/prize", {
          headers: {
            token: localStorage.getItem("token"),
          },
        });
        if (response.data.code === 200) {
          console.log(response.data.data.data);
          this.rewards = response.data.data.data;
        } else {
          ElMessageBox.alert("获取奖品列表失败：" + response.data.msg, "错误", {
            confirmButtonText: "确定",
            type: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching rewards:", error);
        ElMessageBox.alert(
          "获取奖品列表失败：网络错误或其他问题，请稍后再试。",
          "错误",
          {
            confirmButtonText: "确定",
            type: "error",
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.rewards-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

.reward-item {
  width: calc(33% - 40px);
  margin-bottom: 20px;
}

.reward-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.reward-card:hover {
  transform: translateY(-5px);
}

.reward-image {
  width: 100%;
  height: 200px;
  object-fit: fill;
}

.reward-info {
  padding: 15px;
  text-align: center;
}

.reward-info h3 {
  margin: 0 0 5px 0;
  font-size: 18px;
}

.reward-info p {
  margin: 0;
  color: #666;
}

button {
  border: none;
  width: 80%;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  background-image: linear-gradient(to right, #7a5bb6, #7a5bb6);
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

button:disabled {
  cursor: not-allowed;
}
</style>
