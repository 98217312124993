<template>
  <div class="app">
    <el-container class="layout-container">
      <BarSide :activeIndex="activeIndex" />
      <el-container>
        <el-header>
          <div>欢迎使用：<strong>后台管理系统</strong></div>
          <div class="header-actions">
            <el-dropdown placement="bottom-end">
              <span class="el-dropdown__box">
                <el-avatar :src="avatar" style="z-index: 1000" fit="cover" />
              </span>
              <template #dropdown>
                <!-- 这里可以放置dropdown内容 -->
              </template>
            </el-dropdown>
            <el-button
              @click="logout"
              type="danger"
              size="small"
              style="margin-left: 30px; z-index: 1000"
              >退出登录</el-button
            >
          </div>
        </el-header>
        <el-main>
          <div class="box">
            <el-table :data="prizes" style="width: 100%" border stripe>
              <el-table-column
                prop="id"
                label="ID"
                width="50"
              ></el-table-column>
              <el-table-column label="图片URL">
                <template v-slot="scope">
                  <img
                    :src="scope.row.url"
                    style="width: 60px; height: 60px; border-radius: 4px"
                  />
                </template>
              </el-table-column>
              <el-table-column prop="name" label="奖品名称"></el-table-column>
              <el-table-column prop="points" label="积分"></el-table-column>
            </el-table>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import BarSide from "@/components/TabBar.vue";
import axios from "axios";
import image from "@/assets/pic/admin.png";

export default {
  components: {
    BarSide,
  },
  data() {
    return {
      activeIndex: "",
      avatar: image, // 替换为你的头像路径
      prizes: [],
    };
  },
  methods: {
    fetchPrizes() {
      axios
        .get(this.$baseUrlAdmin + "/admin/prize/list", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("获取奖品数据:", response.data.data);
          this.prizes = response.data.data;
        })
        .catch((error) => {
          console.error("请求失败:", error);
        });
    },
    logout() {
      // 发送退出登录请求
      axios
        .put(
          this.$baseUrlAdmin + "/admin/admin/logout",
          {},
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 200) {
            // 请求成功后跳转到登录页面
            localStorage.setItem("token", "");
            this.$router.push("/login");
          } else {
            console.error("退出登录失败:", response.data.msg);
          }
        })
        .catch((error) => {
          console.error("请求失败:", error);
        });
    },
  },
  mounted() {
    this.fetchPrizes(); // 组件挂载后立即获取奖品数据
  },
};
</script>

<style scoped>
.app {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

.box {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-height: 855px;
}

.layout-container {
  height: 100vh;
  margin: 0;
  padding: 0;
}

.layout-container .el-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.header-actions {
  display: flex; /* 使用Flexbox布局 */
  align-items: center; /* 垂直居中对齐 */
  margin-left: auto; /* 将容器推到右侧 */
}

.layout-container .el-dropdown__box {
  display: flex;
  align-items: center;
}
.layout-container .el-header .el-dropdown__box .el-icon {
  color: #999;
  margin-left: 10px;
}
.layout-container .el-header .el-dropdown__box:active,
.layout-container .el-header .el-dropdown__box:focus {
  outline: none;
}
.layout-container .el-main {
  padding: 0;
}

.el-table {
  border-radius: 8px;
}
.el-table th {
  background-color: #f5f7fa;
  color: #333;
}
.el-table td {
  color: #666;
}
</style>
