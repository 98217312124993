<template>
  <div class="app">
    <el-container class="layout-container">
      <BarSide :activeIndex="activeIndex" />
      <el-container>
        <el-header>
          <div>欢迎使用：<strong>后台管理系统</strong></div>
          <div class="header-actions">
            <el-dropdown placement="bottom-end">
              <span class="el-dropdown__box">
                <el-avatar :src="avatar" style="z-index: 1000" fit="cover" />
                <el-icon><CaretBottom /></el-icon>
              </span>
              <template #dropdown>
                <!-- 这里可以放置dropdown内容 -->
              </template>
            </el-dropdown>
            <el-button
              @click="logout"
              type="danger"
              size="small"
              style="margin-left: 8px; z-index: 1000"
              >退出登录</el-button
            >
          </div>
        </el-header>
        <el-main>
          <div class="box">
            <div class="search-container">
              <el-input
                placeholder="请输入用户名"
                v-model="searchQuery"
                @input="handleSearch"
                clearable
                class="search"
              ></el-input>
              <el-button type="primary" @click="search">查询</el-button>
            </div>
            <el-table :data="filteredData" border style="width: 100%">
              <el-table-column prop="nickname" label="用户名"></el-table-column>
              <el-table-column prop="name" label="姓名"></el-table-column>
              <el-table-column prop="phone" label="电话"></el-table-column>
              <el-table-column prop="address" label="地址"></el-table-column>
              <el-table-column label="可用状态">
                <template #default="scope">
                  <el-switch
                    v-model="scope.row.isActive"
                    @change="updateUserStatus(scope.row)"
                    active-text="用"
                    inactive-text="禁"
                  />
                </template>
              </el-table-column>
              <el-table-column prop="points" label="积分"></el-table-column>
            </el-table>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import axios from "axios";
import BarSide from "@/components/TabBar.vue";
import image from "@/assets/pic/admin.png";

export default {
  components: {
    BarSide,
  },
  data() {
    return {
      avatar: image, // 头像地址
      searchQuery: "",
      userData: [],
    };
  },
  computed: {
    filteredData() {
      if (!this.searchQuery) {
        return this.userData;
      }
      return this.userData.filter((user) =>
        user.nickname.includes(this.searchQuery)
      );
    },
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    fetchUserData() {
      axios
        .get(this.$baseUrlAdmin + "/admin/user", {
          params: {
            pageNum: 1,
            pageSize: 100,
          },
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.code) {
            this.userData = response.data.data.rows;
            console.log("获取用户数据成功:", response.data.data.rows);
          }
        })
        .catch((error) => {
          console.error("获取用户数据失败:", error);
        });
    },
    handleSearch() {
      // 搜索逻辑
    },
    logout() {
      axios
        .put(
          this.$baseUrlAdmin + "/admin/admin/logout",
          {},
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 200) {
            // 请求成功后跳转到登录页面
            localStorage.setItem("token", "");
            this.$router.push("/login");
          } else {
            console.error("退出登录失败:", response.data.msg);
          }
        })
        .catch((error) => {
          console.error("请求失败:", error);
        });
    },
    updateUserStatus(user) {
      axios
        .delete(this.$baseUrlAdmin + "/admin/user", {
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            nickname: user.nickname,
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            console.log(
              `用户 ${user.nickname} 的状态已更新为 ${
                user.isActive ? "可用" : "禁用"
              }`
            );
          }
        })
        .catch((error) => {
          console.error("更新用户状态失败:", error);
        });
    },
  },
};
</script>

<style scoped>
.app {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

.box {
  height: 100%;
  width: 100%;
  background-color: #fff;
  padding: 20px;
}
.layout-container {
  height: 100vh;
  margin: 0;
  padding: 0;
}
.layout-container .el-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
.header-actions {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
}
.layout-container .el-dropdown__box {
  display: flex;
  align-items: center;
}
.layout-container .el-header .el-dropdown__box .el-icon {
  color: #999;
  margin-left: 10px;
}
.layout-container .el-header .el-dropdown__box:active,
.layout-container .el-header .el-dropdown__box:focus {
  outline: none;
}
.layout-container .el-main {
  padding: 0;
}

.search-container {
  display: flex;
  justify-content: flex-start; /* left align */
  margin-bottom: 20px;
}

.search {
  width: 200px; /* 可以根据需求调整宽度 */
  margin-right: 10px;
}
</style>
