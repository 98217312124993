// 此文件专门负责项目的路由

import { createRouter, createWebHistory } from "vue-router";
// 引入组件
import HomePage from "@/views/HomePage.vue";
import MatchDetail from "@/views/MatchDetail.vue";
import TeamDetail from "@/views/TeamDetail.vue";
import MyInfo from "@/views/MyInfo.vue";
import Schedule from "@/views/DailySchedule.vue";
import Gift from "@/views/GiftExchange.vue";
import Login from "@/views/LoginForm.vue";
import Register from "@/views/RegisterForm.vue";
import BettingStatus from "@/views/admin/BettingStatus.vue";
import AdminUserManagement from "@/views/admin/AdminUserManagememt.vue";
import PrizeRedemption from "@/views/admin/PrizeRedemption.vue";
import PrizeStatus from "@/views/admin/PrizeStatus.vue";
// 创建路由数组
const routes = [
  {
    path: "/",
    redirect: "/login", // 重定向
  },
  {
    path: "/home", // 路径
    component: HomePage, // 跳转到的组件
  },
  {
    path: "/match", // 路径
    name: "MatchDetail",
    component: MatchDetail, // 跳转到的组件
  },
  {
    path: "/teammatch", // 路径
    name: "TeamDetail",
    component: TeamDetail, // 跳转到的组件
  },
  {
    path: "/myinfo", // 路径
    component: MyInfo, // 跳转到的组件
  },
  {
    path: "/schedule", // 路径
    component: Schedule, // 跳转到的组件
  },
  {
    path: "/login", // 路径
    component: Login, // 跳转到的组件
  },
  {
    path: "/register", // 路径
    component: Register, // 跳转到的组件
  },
  {
    path: "/gift", // 路径
    component: Gift, // 跳转到的组件
  },
  {
    path: "/admin/bettingstatus", // 路径
    component: BettingStatus, // 跳转到的组件
  },
  {
    path: "/admin/usermanagement", // 路径
    component: AdminUserManagement, // 跳转到的组件
  },
  {
    path: "/admin/prizeredemption", // 路径
    component: PrizeRedemption, // 跳转到的组件
  },
  {
    path: "/admin/prizestatus", // 路径
    component: PrizeStatus, // 跳转到的组件
  },
];

// 创建路由器实例
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

// 导出路由器实例
export default router;
