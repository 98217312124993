<template>
  <section>
    <div class="box">
      <div class="square" style="--i: 0"></div>
      <div class="square" style="--i: 1"></div>
      <div class="square" style="--i: 2"></div>
      <div class="square" style="--i: 3"></div>
      <div class="square" style="--i: 4"></div>
      <div v-if="roleSelected === 0" class="container role-selection">
        <h2 class="title">请选择身份</h2>
        <img class="userImg" src="@/assets/pic/用户.png" alt="用户图像" />
        <div class="inputBox" id="user">
          <el-button type="primary" @click="selectRole('user')">用户</el-button>
        </div>
        <img class="adminImg" src="@/assets/pic/管理员.png" alt="管理员图像" />
        <div class="inputBox" id="admin">
          <el-button type="primary" @click="selectRole('admin')"
            >管理员</el-button
          >
        </div>
      </div>

      <div v-if="roleSelected === 1">
        <!-- Squares for design purposes, can be styled as needed -->
        <div class="container">
          <button class="backContainer" @click="goBack">
            <img class="backImg" src="@/assets/pic/back.png" alt="返回按钮" />
            <h2 class="back">返回选择</h2>
          </button>
          <div class="form">
            <h2>欢迎回来</h2>
            <el-form
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
            >
              <div class="inputBox">
                <el-input
                  type="text"
                  v-model="ruleForm.phoneNumber"
                  placeholder="请输入手机号"
                  required
                ></el-input>
              </div>
              <div class="inputBox">
                <el-input
                  type="password"
                  v-model="ruleForm.password"
                  placeholder="请输入密码"
                  required
                ></el-input>
              </div>
              <div class="inputBox">
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >登录</el-button
                >
              </div>
              <p class="forget">
                还没有账户？<router-link to="/register">点击注册</router-link>
              </p>
            </el-form>
          </div>
        </div>
      </div>

      <div v-if="roleSelected === 2">
        <!-- Squares for design purposes, can be styled as needed -->
        <div class="container">
          <button class="backContainer" @click="goBack">
            <img class="backImg" src="@/assets/pic/back.png" alt="返回按钮" />
            <h2 class="back">返回选择</h2>
          </button>
          <div class="form">
            <h2>欢迎回来</h2>
            <el-form
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
            >
              <div class="inputBox">
                <el-input
                  type="text"
                  v-model="ruleForm.phoneNumber"
                  placeholder="请输入手机号"
                  required
                ></el-input>
              </div>
              <div class="inputBox">
                <el-input
                  type="password"
                  v-model="ruleForm.password"
                  placeholder="请输入密码"
                  required
                ></el-input>
              </div>
              <div class="inputBox">
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >登录</el-button
                >
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios"; // 导入 axios

export default {
  data() {
    return {
      roleSelected: 0,
      selectedRole: "",
      ruleForm: {
        phoneNumber: "",
        password: "",
      },
      rules: {
        phoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: this.checkPhoneNumber, trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空！", trigger: "blur" },
          { validator: this.validatePass, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    selectRole(role) {
      this.selectedRole = role; // 选择身份后显示登录界面
      if (role === "user") {
        this.roleSelected = 1;
      }
      if (role === "admin") {
        this.roleSelected = 2;
      }
    },
    goBack() {
      this.roleSelected = 0;
    },
    validatePass(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    },
    checkPhoneNumber(rule, value, callback) {
      const reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 检查手机号是否为空或格式是否正确
          if (!this.ruleForm.phoneNumber) {
            this.$message.error("手机号不能为空");
            return;
          }
          if (!this.ruleForm.password) {
            this.$message.error("密码不能为空");
            return;
          }
          // 如果手机号有效，调用登录方法
          if (this.roleSelected === 1) {
            this.userLogin(this.ruleForm);
          }
          if (this.roleSelected === 2) {
            this.adminLogin(this.ruleForm);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 登录方法
    userLogin(form) {
      axios
        .post(this.$baseUrl + "/user/user/login", null, {
          params: {
            phone: form.phoneNumber,
            password: form.password,
          },
        })
        .then((response) => {
          console.log("登录成功，返回的数据:", response);
          console.log("登录成功，返回的数据:", response.data);
          if (response.status === 200 && response.data.code === 200) {
            console.log("登录成功，返回的数据:", response.data);
            localStorage.setItem("token", response.data.data);
            this.$router.push("/home"); // 假设登录成功后跳转到主页
          } else {
            this.$message.error(
              response.data.msg || "登录失败，请检查手机号与密码"
            );
          }
        })
        .catch((error) => {
          console.error("请求失败:", error);
          this.$message.error("登录请求失败，请检查网络连接或联系管理员");
        });
    },

    adminLogin(form) {
      axios
        .post(this.$baseUrlAdmin + "/admin/admin/login", null, {
          params: {
            phone: form.phoneNumber,
            password: form.password,
          },
        })
        .then((response) => {
          // 检查响应状态码和code

          console.log("管理员登录成功，返回的数据:", response.data);
          if (response.status === 200 && response.data.code === 200) {
            console.log("管理员登录成功，返回的数据:", response.data);
            localStorage.setItem("token", response.data.data);
            this.$router.push("/admin/bettingstatus");
          } else {
            this.$message.error(
              response.data.msg || "登录失败，请检查手机号与密码"
            );
          }
        })
        .catch((error) => {
          console.error("请求失败:", error);
          this.$message.error("登录请求失败，请检查网络连接或联系管理员");
        });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;

  padding: 0;

  box-sizing: border-box;

  font-family: "Poppins", sans-serif;
}

body {
  overflow: hidden;
}

section {
  display: flex;

  justify-content: center;

  align-items: center;

  min-height: 100vh;
}

.box {
  position: relative;
}

.box .square {
  position: absolute;

  backdrop-filter: blur(5px);

  box-shadow: 0 25px 45px rgb(0, 0, 0, 0.1);

  border: 1px solid rgb(255, 255, 255, 0.5);

  border-right: 1px solid rgb(255, 255, 255, 0.2);

  border-bottom: 1px solid rgb(255, 255, 255, 0.2);

  background: rgb(255, 255, 255, 0.1);

  border-radius: 10px;

  animation: animate 10s linear infinite;

  animation-delay: calc(-1s * var(--i));
}

/*      */

@keyframes animate {
  0%,
  100% {
    transform: translateY(-40px);
  }

  50% {
    transform: translate(40px);
  }
}

.box .square:nth-child(1) {
  top: -50px;

  right: -60px;

  width: 100px;

  height: 100px;
}

.box .square:nth-child(2) {
  top: 150px;

  left: -100px;

  width: 120px;

  height: 120px;

  z-index: 2;
}

.box .square:nth-child(3) {
  bottom: 50px;

  right: -60px;

  width: 80px;

  height: 80px;

  z-index: 2;
}

.box .square:nth-child(4) {
  bottom: -80px;

  left: 100px;

  width: 50px;

  height: 50px;
}

.box .square:nth-child(5) {
  top: -90px;

  left: 140px;

  width: 60px;

  height: 60px;
}

.container {
  position: relative;

  width: 400px;

  min-height: 400px;

  background: rgb(255, 255, 255, 0.1);

  border-radius: 10px;

  display: flex;

  justify-content: center;

  align-items: center;

  backdrop-filter: blur(5px);

  box-shadow: 0 25px 45px rgb(0, 0, 0, 0.1);

  border: 1px solid rgb(255, 255, 255, 0.5);

  border-right: 1px solid rgb(255, 255, 255, 0.2);

  border-bottom: 1px solid rgb(255, 255, 255, 0.2);
}

.form {
  position: relative;

  height: 100%;

  width: 100%;

  padding: 40px;
}

.form h2 {
  position: relative;

  color: #fff;

  font-size: 24px;

  font-weight: 600;

  letter-spacing: 1px;

  margin-bottom: 40px;
}

.form h2::before {
  content: "";

  position: absolute;

  left: 0;

  bottom: -10px;

  width: 80px;

  height: 4px;

  background: #fff;
}

.form .inputBox {
  width: 100%;

  margin-top: 20px;
}

.form .inputBox.el-input {
  width: 100%;

  background: rgb(255, 255, 255, 0.2);

  border: none;

  outline: none;

  padding: 10px 20px;

  border-radius: 35px;

  border: 1px solid rgb(255, 255, 255, 0.5);

  border-right: 1px solid rgb(255, 255, 255, 0.2);

  border-bottom: 1px solid rgb(255, 255, 255, 0.2);

  font-size: 16px;

  letter-spacing: 1px;

  color: #fff;

  box-shadow: 0 5px 15px rgb(0, 0, 0, 0.05);
}

.form .inputBox.el-input::placeholder {
  color: #fff;
}

.el-button {
  background: #fff;

  color: #666;

  width: 100px;
  height: 40px;

  cursor: pointer;

  margin-bottom: 20px;

  font-weight: 800;
  /* size: medium; */
  border-color: transparent;
}

.forget {
  margin-top: 5px;

  color: #8f7ab4;
}
.forget :hover {
  margin-top: 5px;

  color: #48346a;
}

.forget a {
  color: #8f7ab4;

  font-weight: 600;
}

.backContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100px;
  height: 30px;
  font-size: 18px;
  color: #fff;
  border-radius: 35px;
  background-color: #d2d2d200;
  border: none;
  z-index: 2;
}
.back {
  position: absolute;
  font-size: 18px;
  top: 0px;
  left: 20px;
}
.back:hover {
  position: absolute;
  font-size: 18px;
  top: 0px;
  left: 20px;
  color: #8f7ab4;
}
.backImg {
  position: absolute;
  top: 3px;
  left: 0px;
  width: 18px;
  height: 18px;
}

.title {
  position: absolute;
  color: #fff;
  top: 70px;
}

#user {
  position: absolute;
  top: 170px;
  margin: auto;
}

.userImg {
  position: absolute;
  top: 175px;
  left: 100px;
  width: 32px;
  height: 32px;
}
#admin {
  position: absolute;
  top: 250px;
  margin: auto;
}
.adminImg {
  position: absolute;
  top: 250px;
  left: 95px;
  width: 40px;
  height: 40px;
}
</style>
