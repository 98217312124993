<template>
  <div class="app">
    <NavComponent />
    <img src="@/assets/pic/logo3.png" alt="sale" class="logo" />
    <h1 class="medal_title">比赛竞猜</h1>
    <h1 class="medal_title2">Competiton Guuessing</h1>

    <div class="medal-card">
      <div class="date-picker">
        <span class="demonstration">日期</span>
        <select v-model="selectedDate" @change="loadMatches" class="date-select">
          <option v-for="date in dateOptions" :key="date" :value="date">
            {{ date }}
          </option>
        </select>
      </div>
    </div>

    <div class="medal-board">
      <div class="matches">
        <div v-for="(match, index) in paginatedMatches" :key="index" class="match">
          <router-link
            :to="getMatchLink(match)"
            @click.prevent="goToMatchDetails(match)"
          >
          <div class="match-info">
            <div class="info-row1">
              <h5>{{ match.name }}</h5>
            </div>
            <div class="info-row">
              <div class="info-cell">预测开始时间</div>
              <div class="info-cell time">{{ match.voteStartTime }}</div>
            </div>
            <div class="info-row">
              <div class="info-cell">预测截止时间</div>
              <div class="info-cell time">{{ match.startTime }}</div>
            </div>
            <div class="info-row1">
              <div class="info-cell" v-if="match.isTeamCompetition">
                <img :src="getFlagUrl(match.homeFlag)" alt="flag" class="flag" />
                {{ match.homeName}}
              </div>
            </div>
            <div class="info-row">
              <div class="info-cell" v-if="match.isTeamCompetition">
                <img :src="getFlagUrl(match.awayFlag)" alt="flag" class="flag" />
                {{ match.awayName }}
              </div>
            </div>
          </div>
        </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavComponent from '@/components/AppNav.vue';
import axios from 'axios';

// 生成日期数组
const getDates = (startDate, endDate) => {
  const date = new Date(startDate);
  const dates = [];
  while (date <= new Date(endDate)) {
    dates.push(
      `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date
        .getDate()
        .toString()
        .padStart(2, '0')}`
    );
    date.setDate(date.getDate() + 1);
  }
  return dates;
};

export default {
  components: {
    NavComponent
  },
  data() {
    return {
      activeStage: null,
      matches: [],
      selectedDate: '2024-07-24', // 默认日期
      dateOptions: getDates('2024-07-24', '2024-08-11'),
      currentPage: 1,
      pageSize: 100,
      input: ''
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.matches.length / this.pageSize);
    },
    paginatedMatches() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.matches.slice(start, start + this.pageSize);
    }
  },
    methods: {

      getMatchLink(matchData) {
    return matchData.isTeamCompetition 
      ? { name: 'TeamDetail', params: { id: matchData.id } } 
      : { name: 'MatchDetail', params: { id: matchData.id } };
  },

  goToMatchDetails(matchData) {
    const routeParams = {
      name: matchData.isTeamCompetition ? 'TeamDetail' : 'MatchDetail',
      query: { id: matchData.id },
    };
    console.log('routeParams:', routeParams);
    this.$router.push(routeParams);
  },

  async loadMatches() {
  this.matches = []; // 清空之前的比赛数据

  console.log(`Loading matches for date: ${this.selectedDate}`);

  try {
    // 从后端接口获取数据
    const response = await axios.get(`http://116.205.107.96:7770/user/game?date=${this.selectedDate}`);
    const matchData = response.data; // 假设接口返回的数据格式正确
    console.log('Match data:', matchData); // 日志3：打印接口返回的数据
    this.processMatches(matchData.data);
  } catch (error) {
    console.error('Error loading match data:', error);
  }
},






processMatches(matches) {
  console.log('正在处理比赛数据...'); // 日志4：开始处理数据
  this.matches = matches.map(match => {
    try {

      // 将 match.startTime 转换为 Date 对象
      const startTime = new Date(match.startTime);
      
      // 设置 voteStartTime 为 startTime 的前一天，并格式化为与 startTime 相同的格式
      startTime.setDate(startTime.getDate() - 1);
      const year = startTime.getFullYear();
      const month = String(startTime.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始
      const day = String(startTime.getDate()).padStart(2, '0');
      const hours = String(startTime.getHours()).padStart(2, '0');
      const minutes = String(startTime.getMinutes()).padStart(2, '0');
      const seconds = String(startTime.getSeconds()).padStart(2, '0');

      match.voteStartTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // 格式化为与 startTime 相同的字符串
      console.log(`voteStartTime: ${match.voteStartTime}startTime: ${match.startTime}`); 

      console.log(`国家代码1: ${match.awayCode}国家代码2: ${match.homeCode}`); // 日志5：打印客场国家代码

      if (match.awayCode) {
        const awayFlag = require(`@/assets/match/country_images/${match.awayCode}.png`);
        match.awayFlag = awayFlag; // 将 awayFlag 添加到 match 对象中
        console.log(`为 ${match.awayName} 加载的国旗: ${awayFlag}和${match.awayFlag}`); // 日志5：打印客场图片路径
      }

      if (match.homeCode) {
        const homeFlag = require(`@/assets/match/country_images/${match.homeCode}.png`);
        match.homeFlag = homeFlag; // 将 homeFlag 添加到 match 对象中
        console.log(`为 ${match.homeName} 加载的国旗: ${homeFlag}和${match.homeFlag}`); // 日志5：打印主场图片路径
      }
    } catch (error) {
      console.warn(`加载国旗失败:`, error); // 日志：加载图片失败
    }

    return match; // 返回处理后的比赛对象
  });
},



  // 获取国旗图片的URL
  getFlagUrl(noc) {
    return noc;
  }
},

  mounted() {
    // 加载默认日期的赛程
    this.loadMatches();
  }
};
</script>


<style scoped>
.app {
  text-align: center;
}

.logo {
  display: inline-block;
  width: 100px;
  height: auto;
}

.medal_title {
  font-weight: 100;
  color: #7a5bb6;
}

.medal_title2 {
  font-weight: 100;
  color: #7a5bb6;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.medal-card {
  width: 58%;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
  padding: 30px 50px 30px 50px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #7a5bb6;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  min-height: 50px;
}

.demonstration {
  color: #fff;
  font-weight: 500;
  margin-right: 10px;
}
.medal-board {
  max-height: 80000px;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
}

.match {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.matches {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.match h5 {
  margin: 10px 30px 10px 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  text-align: left;
  font-size: medium;
}

.match-info {
  display: flex;
  flex-direction: column;
  margin: 10px 50px 10px 50px;
}

.flag {
  width: 24px;
  height: auto;
  margin-right: 8px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.info-row1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-cell {
  flex: 1;
  display: flex;
  padding: 8px;
  text-align: left;
  color: #000;
}

.time {
  justify-content: flex-start;
}

.score,
.status {
  justify-content: flex-end;
}

.date-select {
  width: 150px;
  padding: 10px;
  font-size: 10px;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  overflow-y: scroll;
  height: 30px;
}

.match h5 {
  color: #000;
}
</style>


