<template>
  <nav class="section__container nav__container">
    <img src="@/assets/pic/nav1.png" alt="sale" class="nav__logo" />
    <ul class="nav__links">
      <li class="link"><router-link to="/home">首页</router-link></li>
      <li class="link"><router-link to="/schedule">比赛竞猜</router-link></li>
      <li class="link"><router-link to="/gift">积分兑换</router-link></li>
      <li class="link"><router-link to="/myinfo">我的信息</router-link></li>
    </ul>
    <div class="nav__icons">
      <span><i class="ri-shield-user-line"></i></span>
      <span><i class="ri-search-line"></i></span>
      <span><i class="ri-shopping-bag-2-line"></i></span>
      <!-- <div class="nav__time">{{ currentTime }}</div> -->
      <div class="fake__time">
        <div>2024/</div>
        <input
          type="number"
          class="transparent-input"
          v-model="fmonth"
          min="1"
          max="12"
          placeholder="月"
        />
        <!-- <div>{{ fmonth }}</div> -->
        <div>/</div>
        <input
          type="number"
          class="transparent-input"
          v-model="fday"
          min="1"
          max="31"
          placeholder="日"
        />
        <!-- <div>{{ fday }}</div> -->
        <div>&nbsp;&nbsp;</div>
        <input
          type="number"
          class="transparent-input"
          v-model="fhour"
          min="0"
          max="23"
          placeholder="时"
        />
        <!-- <div>{{ fhour }}</div> -->
        <div>:</div>
        <input
          type="number"
          class="transparent-input"
          v-model="fminute"
          min="0"
          max="59"
          placeholder="分"
        />
        <!-- <div>{{ fminute }}</div> -->
      </div>
      <span @click="logout"
        ><img src="@/assets/pic/close.png" alt="退出" class="nav__logout-icon"
      /></span>
    </div>
  </nav>
</template>

<script>
import axios from "axios";
export default {
  name: "NavComponent",
  data() {
    return {
      currentTime: "",
      ws: null,
      fmonth: 8,
      fday: 25,
      fhour: 18,
      fminute: 30,
    };
  },
  // watch: {
  //   // 监听fmonth, fday, fhour, fminute的变化
  //   fmonth(newVal, oldVal) {
  //     this.setFakeTime();
  //   },
  //   fday(newVal, oldVal) {
  //     this.setFakeTime();
  //   },
  //   fhour(newVal, oldVal) {
  //     this.setFakeTime();
  //   },
  //   fminute(newVal, oldVal) {
  //     this.setFakeTime();
  //   }
  // },
  methods: {
    logout() {
      axios
        .put(this.$baseUrl + "/user/user/logout", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          // 接口调用成功，处理响应,
          if (response.data.code) {
            // 清空 token
            localStorage.setItem("token", "");
            localStorage.setItem("token", "");
            // 重定向到登录页面
            this.$router.push("/login");
            //} else {
            // 处理错误情况
            // console.error("退出失败:", response.data.msg);
          }
        })
        .catch((error) => {
          // 处理请求错误
          console.error("退出接口调用失败:", error);
        });
    },
    setFakeTime() {
      const fakeTime = new Date(
        2024,
        this.fmonth - 1,
        this.fday,
        this.fhour,
        this.fminute
      );
      // this.currentTime = fakeTime
      //   .toLocaleTimeString("zh-CN", {
      //     hour12: false, // 24小时制
      //     year: "numeric",
      //     month: "numeric",
      //     day: "numeric",
      //     hour: "numeric",
      //     minute: "numeric",
      //     second: "numeric",
      //   })
      //   .replace(/[年]+.*?[月]+.*?[日]+/, "")
      //   .replace("小时", "点")
      //   .slice(0, -3); // 移除秒
      this.$date = fakeTime;
      console.log("新时间是" + this.$date);
    },
  },
  mounted() {
    // 设置时间
    this.setFakeTime();
  },
};
</script>

<style>
.nav__container {
  padding: 2rem 1rem;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  top: 0;
  z-index: 100;
}

.nav__logo {
  width: 120px;
  height: auto;
}

.nav__links {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.link a {
  padding: 0 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--primary-pink);
  transition: 0.3s;
}

.link a:hover {
  color: white !important;
  text-decoration: underline;
}

.nav__icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.nav__icons span {
  font-size: 1.25rem;
  cursor: pointer;
}

/* 添加退出按钮的样式 */
.nav__icons span:last-child {
  color: var(--primary-pink);
  transition: 0.3s;
}

.nav__icons span:last-child:hover {
  color: white;
}

.nav__logout-icon {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  transition: 0.3s;
}

.nav__logout-icon:hover {
  opacity: 0.8;
}

.nav__time {
  margin-left: auto;
  font-size: 1rem;
  color: var(--primary-pink);
}

.fake__time {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  font-size: 1rem;
  color: var(--primary-pink);
}

.transparent-input {
  width: 20px;
  /* margin: 0 5px; */
  /* padding: 5px; */
  border: none;
  border-radius: 4px;
  background-color: transparent; /* 设置背景透明 */
  color: inherit; /* 文字颜色继承自父元素或默认颜色 */
  font-size: 16px;
  text-align: center;
}

/* 针对所有浏览器的通用方法 */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
