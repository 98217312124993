import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// 关闭 Vue 的生产提示
// 在 Vue 3 中，使用 createApp() 时不需要设置 productionTip
// Vue.config.productionTip = false;

// 创建 Vue 应用实例
const app = createApp(App);
// app.config.globalProperties.$token =
//   "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJlY2U2YWU1OWVlZDY0YWM3OTQyNzRiMGVmMWNiM2I2MiIsInN1YiI6IjE2IiwiaXNzIjoibGpyIiwiaWF0IjoxNzMwMzgzNDgxLCJleHAiOjE3MzA5ODgyODF9.p3wcInYPQN_w155kogREt6jr7auhIskkrZepmPsV7zg";
app.config.globalProperties.$baseUrl = "http://116.205.107.96:7770";
app.config.globalProperties.$baseUrlAdmin = "http://116.205.107.96:8989";
app.config.globalProperties.$date = new Date(2024, 6, 25, 18, 30); // 2024年7月25日18点30分
// 使用路由
app.use(router);
// 全局注册 Element Plus 组件
app.use(ElementPlus);

// 挂载 Vue 应用实例
app.mount("#app");
