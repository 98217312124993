<template>
  <div class="container">
    <div class="header">
      <button class="back-button" @click="goBack">返回</button>
    </div>
    <div class="match-results">
      <img src="@/assets/pic/logo1.png" alt="sale" class="logo" />
      <h4 class="game">
        {{ disciplineName }}, {{ name }}, {{ competitionId }}
      </h4>
      <h3 class="vs" v-if="homeName && awayName">
        {{ this.homeName }} vs {{ this.awayName }}
      </h3>
    </div>
    <!-- 显示获胜国家 -->
    <!-- <div class="showWin">
      {{ homeName }} 获胜 ! ! !
     </div> -->
    <!-- 国家投票按钮 -->
    <div class="match-vote">
      <!-- <img :src="this.flagUrl1" alt="" class="flag" /> -->
      <h3>
        {{
          homeName
        }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vs&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
          awayName
        }}
      </h3>
      <!-- <img :src="this.flagUrl2" alt="" class="flag" /> -->
      <div class="vote-buttons">
        <button
          @click="voteForTeam(homeId)"
          :disabled="hasVoted"
          class="bottom"
        >
          {{ chooseId === homeId ? "已投票" : "投票" }}
        </button>
        <button
          @click="voteForTeam(awayId)"
          :disabled="hasVoted"
          class="bottom"
        >
          {{ chooseId === awayId ? "已投票" : "投票" }}
        </button>
      </div>
    </div>
    <!-- 展示每个国家运动员 -->
    <div class="showAthlete">
      <!-- 导航栏标题 -->
      <div class="countrybar">
        <!-- <button @click="currentCountry = 'A'">{{ team1Name }}</button> -->
        <!-- <button @click="currentCountry = 'B'">{{ team2Name }}</button> -->
        <button @click="showHomeAthletes()" class="countryButtonL">
          {{ this.homeName }}
        </button>
        <button @click="showAwayAthletes()" class="countryButtonR">
          {{ this.awayName }}
        </button>
      </div>
      <!-- 运动员展示 -->
      <div class="athletes">
        <ul>
          <li v-for="athlete in athletes" :key="athlete.id">
            {{ athlete }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      chooseId: 0,
      hasVoted: false,
      team1Voted: false,
      team2Voted: false,
      competitionId: 4,
      chooseTeam: 0,
      athletes: [],
      id: 0,
      name: "",
      startTime: "",
      flagUrl1: "",
      flagUrl2: "",
      // athletes: [],
      homeId: 0,
      awayId: 0,
      homeCode: "",
      awayCode: "",
      homeName: "",
      awayName: "",
      awayAthletes: [],
      homeAthletes: [],
    };
  },
  created() {
    // 在created钩子中获取路由参数
    this.competitionId = Number(this.$route.query.id);
    // console.log("homeCode" + this.homeCode);
    console.log("competitionId     " + typeof this.competitionId);
  },
  methods: {
    goBack() {
      window.history.back();
    },
    fetchDetail() {
      console.log(this.competitionId);
      axios
        .get(`${this.$baseUrl}/user/game/team/${this.competitionId}`, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          this.id = Number(response.data.data.id); // 比赛对应的id
          this.name = response.data.data.name; // 比赛的名字
          this.startTime = response.data.data.startTime; // 比赛开始时间
          this.isVoted = response.data.data.isVoted; // 是否已经投票
          this.votedId = response.data.data.votedId; // 投票国家的id
          this.isVoted = response.data.data.isVoted; // 是否已经投票
          this.homeId = response.data.data.homeId; // 左侧国家id
          this.awayId = response.data.data.awayId; // 右侧国家id
          this.homeCode = response.data.data.homeCode; // 左侧国家国旗代码
          this.awayCode = response.data.data.awayCode; // 右侧国家国旗代码
          this.homeName = response.data.data.homeName; // 左侧国家名字
          this.awayName = response.data.data.awayName; // 右侧国家名字
          this.homeAthletes = response.data.data.homeAthletes; // 左侧国家运动员
          this.awayAthletes = response.data.data.awayAthletes; // 右侧国家运动员
          this.athletes = this.homeAthletes;
          // this.flagUrl1 = `https://gstatic.olympics.com/s1/t_original/static/noc/oly/3x2/180x120/${this.homeCode}.png`
          // this.flagUrl2 = `https://gstatic.olympics.com/s1/t_original/static/noc/oly/3x2/180x120/${this.awayCode}.png`
          // console.log(this.flagUrl2);
          console.log(response.data.data.homeName);
          console.log(this.homeName);
        })
        .catch((err) => {
          console.error("Error fetching guesses: ", err);
        });
    },
    async voteForTeam(tmpId) {
      // 投票之后另一个按钮变灰
      const datetime = this.$date;
      this.chooseId = tmpId;
      this.hasVoted = true;
      // const currentTime = new Date().toISOString();
      console.log("type    " + typeof tmpId);

      try {
        const response = await fetch(
          `http://116.205.107.96:7770/user/game/vote`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            },
            body: JSON.stringify({
              competitionId: this.competitionId,
              objectId: Number(tmpId),
              currentTime: this.formatDateTime(datetime),
            }),
          }
        );

        const result = await response.json();
        if (result.code) {
          this.votedId = this.chooseId;
          this.hasVoted = true;
          console.log("上传数据成功");
        } else {
          console.error("投票失败:", result.msg);
        }
      } catch (error) {
        console.error("投票请求失败:", error);
      }
    },
    showHomeAthletes() {
      this.athletes = this.homeAthletes;
    },
    showAwayAthletes() {
      this.athletes = this.awayAthletes;
    },
    formatDateTime(date) {
      const year = date.getFullYear(); // 获取年份
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 获取月份，月份从0开始，所以加1
      const day = date.getDate().toString().padStart(2, "0"); // 获取日期
      const hours = date.getHours().toString().padStart(2, "0"); // 获取小时
      const minutes = date.getMinutes().toString().padStart(2, "0"); // 获取分钟
      const seconds = date.getSeconds().toString().padStart(2, "0"); // 获取秒

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // 返回格式化的字符串
    },
  },
  // 组件挂载后立即获取投票数据
  mounted() {
    this.fetchDetail();
  },
};
</script>

<style scoped>
.container {
  background: #ffffffca;
  position: relative;
  min-height: 130vh;
  justify-content: center;
}

.header {
  position: absolute; /* 绝对定位 */
  top: 10px; /* 距离顶部10px */
  left: 10px; /* 距离左侧10px */
  padding: 10px;
  z-index: 1000; /* 确保按钮在最上层 */
}

.back-button {
  margin-top: 10px;
  font-size: 10px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f2f2f2d0;
  width: 60px;
  height: 30px;
  border-radius: 10px;
  border: none;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #ddd;
}

.match-results {
  font-family: Arial, sans-serif;
  background-color: rgba(199, 137, 168, 0.95);
  height: 250px;
  margin-top: -100px;
}

.logo {
  display: inline-block;
  width: 70px;
  height: auto;
  margin-top: 20px;
}

.game {
  font-size: small;
  color: #ffffffda;
  font-weight: 500;
  margin-top: 20px;
}

.vs {
  margin-top: 30px;
  font-size: 30px;
  color: #ffffffda;
  font-weight: 500;
}

/* 显示获胜国家 */
.showWin {
  margin-top: 10px;
  font-size: 20px;
}

/* 投票 */
.match-vote {
  width: 100%;
  margin-top: 4vh;
  margin-bottom: 4vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* 添加这个属性 */
}

.vote-buttons {
  display: flex;
  justify-content: space-around;
  width: 50vh;
  border: none;
  /* background-color: #333; */
}

.bottom {
  width: 80px;
  height: 40px;
  border-radius: 25px;
  margin-top: 10px;
  border: none;
  background-color: #7a5bb6;
  color: white;
}

/* 展示队员 */
.showAthlete {
  width: 70%;
  height: 20%;
  display: relative;
  left: 50%;
  transform: translateX(22%);

  /* justify-content: start; */
  flex-direction: column;
  /* background-color: rgb(255, 255, 255); */
}

.countrybar {
  width: 50vh;
  display: flex;
  /* margin-left: -13vh; */
  margin-top: 4vh;
  margin-bottom: -1.5vh;
}

.countryButtonL {
  width: 12vh;
  height: 4vh;
  border: none;
  border-radius: 25px 0 0 0;
  border-right: 1px solid rgb(192, 143, 202); /* 只设置左侧边框，颜色和粗细可以根据需要调整 */
  font-size: 16px;
  font-family: Arial, sans-serif; /* 设置字体家族 */
  line-height: 4vh; /* 设置行高，使文本垂直居中 */
  text-align: center; /* 使文本水平居中 */
  cursor: pointer; /* 鼠标悬停时显示指针 */
  background-color: pink;
}
.countryButtonR {
  /* color: red; */
  width: 12vh;
  height: 4vh;
  border: none;
  border-radius: 0 25px 0 0;
  border-left: 1px solid rgb(192, 143, 202); /* 只设置左侧边框，颜色和粗细可以根据需要调整 */
  font-size: 16px;
  font-family: Arial, sans-serif; /* 设置字体家族 */
  line-height: 4vh; /* 设置行高，使文本垂直居中 */
  text-align: center; /* 使文本水平居中 */
  cursor: pointer; /* 鼠标悬停时显示指针 */
  background-color: pink;
}

.athletes {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  border-radius: 0 25px 25px 25px;
  background-color: rgb(199, 140, 169);
}

.athletes ul {
  list-style-type: none; /* 移除列表项前的项目符号 */
  padding: 0%;
  margin: 0%;
}

.athletes li {
  margin: 1.5vh;
  height: 30px;
}
</style>
