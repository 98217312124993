<template>
  <div class="app">
    <el-container class="layout-container">
      <BarSide :activeIndex="activeIndex" />
      <el-container>
        <el-header>
          <div>欢迎使用：<strong>后台管理系统</strong></div>
          <div class="header-actions">
            <el-dropdown placement="bottom-end">
              <span class="el-dropdown__box">
                <el-avatar :src="avatar" style="z-index: 1000" fit="cover" />
                <el-icon><CaretBottom /></el-icon>
              </span>
              <template #dropdown> </template>
            </el-dropdown>
            <el-button
              @click="logout"
              type="danger"
              size="small"
              style="margin-left: 8px; z-index: 1000"
              >退出登录</el-button
            >
          </div>
        </el-header>
        <el-main>
          <div class="box">
            <!-- 表格定义列 -->
            <el-table :data="guesses" class="table" border style="width: 90%">
              <el-table-column
                class="table-head"
                prop="competition"
                label="比赛名称"
              ></el-table-column>
              <el-table-column
                class="table-head"
                prop="nation1"
                label="比赛队伍1"
              ></el-table-column>
              <el-table-column
                class="table-head"
                prop="nation2"
                label="比赛队伍2"
              ></el-table-column>
              <el-table-column
                class="table-head"
                prop="winPercent"
                label="正确率"
              ></el-table-column>
            </el-table>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import axios from "axios";
import BarSide from "@/components/TabBar.vue";
import image from "@/assets/pic/admin.png";

export default {
  data() {
    return {
      avatar: image,
      activeIndex: "",
      currentPage: 0,
      pageSize: 30,
      total: 0,
      guesses: [],
    };
  },
  components: {
    BarSide,
  },
  methods: {
    handlePageChange(newPage) {
      this.currentPage = newPage;
    },
    logout() {
      // 发送退出登录请求
      axios
        .put(
          this.$baseUrlAdmin + "/admin/admin/logout",
          {},
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 200) {
            // 请求成功后跳转到登录页面
            localStorage.setItem("token", "");
            this.$router.push("/login");
          } else {
            console.error("退出登录失败:", response.data.msg);
          }
        })
        .catch((error) => {
          console.error("请求失败:", error);
        });
    },
    fetchGuesses() {
      axios
        .get(this.$baseUrlAdmin + "/admin/vote", {
          params: {
            pageNum: this.currentPage,
            pageSize: this.pageSize,
          },
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.guesses = response.data.data.rows;
          this.total = Number(response.data.data.total);
        })
        .catch((err) => {
          console.error("Error fetching guesses: ", err);
        });
    },
    // 监听页码变化
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.fetchGuesses();
    },
    // 监听页面大小变化
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.currentPage = 0; // 改变页面大小时，重置到第一页
      this.fetchGuesses();
    },
  },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  mounted() {
    this.fetchGuesses(); // 组件挂载后立即获取投票数据
  },
};
</script>

<style scoped>
.app {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

.box {
  height: 100%;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(216, 73, 73, 0.1);
  display: flex;
  justify-content: center;
  min-height: 855px;
}

.table {
  width: 80%;
}

.table th,
.table td {
  border-bottom: 1px solid #409eff;
  border-left: 1px solid #409eff;
}

.table-head {
  border-right: 2px solid rgb(155, 79, 79); /* 列头右侧边框 */
  background-color: rgb(179, 179, 179);
}

.layout-container {
  height: 100vh;
  margin: 0;
  padding: 0;
}

.layout-container .el-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.header-actions {
  display: flex;
  align-items: center;
  margin-left: auto; /* 将容器推到右侧 */
}

.layout-container .el-dropdown__box {
  display: flex;
  align-items: center;
}

.layout-container .el-header .el-dropdown__box .el-icon {
  color: #999;
  margin-left: 10px;
}

.layout-container .el-header .el-dropdown__box:active,
.layout-container .el-header .el-dropdown__box:focus {
  outline: none;
}

.layout-container .el-main {
  padding: 0;
}
</style>
