<template>
  <div class="app">
    <el-container class="layout-container">
      <BarSide :activeIndex="activeIndex" />
      <el-container>
        <el-header>
          <div>欢迎使用：<strong>后台管理系统</strong></div>
          <div class="header-actions">
            <!-- 新增的容器 -->
            <el-dropdown placement="bottom-end">
              <span class="el-dropdown__box">
                <el-avatar :src="avatar" style="z-index: 1000" fit="cover" />
                <el-icon><CaretBottom /></el-icon>
              </span>
              <template #dropdown>
                <!-- 这里可以放置dropdown内容 -->
              </template>
            </el-dropdown>
            <el-button
              @click="logout"
              type="danger"
              size="small"
              style="margin-left: 8px; z-index: 1000"
              >退出登录</el-button
            >
          </div>
        </el-header>
        <el-main>
          <div class="box">
            <div class="search-container">
              <el-input
                v-model="searchQuery"
                placeholder="请输入用户名"
                style="width: 200px; margin-right: 10px"
              />
              <el-button type="primary" @click="search">查询</el-button>
            </div>
            <el-table :data="filteredTableData" border style="width: 90%">
              <el-table-column prop="userName" label="用户名" />
              <el-table-column prop="prizeName" label="奖品名" />
              <el-table-column prop="prizePoints" label="积分" />
            </el-table>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import BarSide from "@/components/TabBar.vue";
import axios from "axios"; // 引入 Axios
import image from "@/assets/pic/admin.png";

export default {
  components: {
    BarSide,
  },
  data() {
    return {
      avatar: image, // 头像地址
      tableData: [], // 初始化为空，待从后端获取
      searchQuery: "",
    };
  },
  computed: {
    filteredTableData() {
      if (!this.searchQuery) {
        return this.tableData;
      }
      return this.tableData.filter((item) =>
        item.userName.includes(this.searchQuery)
      );
    },
  },
  methods: {
    fetchData() {
      axios
        .get(this.$baseUrlAdmin + "/admin/prize/exchange", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.tableData = response.data.data.map((item) => ({
              userName: item.userName,
              prizeName: item.prizeName,
              prizePoints: item.prizePoints,
            }));
          } else {
            console.error("获取数据失败:", response.data.msg);
          }
        })
        .catch((error) => {
          console.error("获取数据失败:", error);
        });
    },
    search() {
      // 查询逻辑已经在 computed 属性中实现
    },
    logout() {
      // 发送退出登录请求
      axios
        .put(
          this.$baseUrlAdmin + "/admin/admin/logout",
          {},
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code) {
            // 请求成功后跳转到登录页面
            localStorage.setItem("token", "");
            this.$router.push("/login");
          } else {
            console.error("退出登录失败:", response.data.msg);
          }
        })
        .catch((error) => {
          console.error("请求失败:", error);
        });
    },
  },
  mounted() {
    this.fetchData(); // 在组件挂载后获取数据
  },
};
</script>

<style scoped>
.app {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

.box {
  height: 100%;
  width: 100%;
  background-color: #fff;
  padding: 20px;
}

.layout-container {
  height: 100vh;
  margin: 0;
  padding: 0;
}

.layout-container .el-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.header-actions {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  margin-left: auto; /* 推到右侧 */
}

.layout-container .el-dropdown__box {
  display: flex;
  align-items: center;
}

.layout-container .el-header .el-dropdown__box .el-icon {
  color: #999;
  margin-left: 10px;
}

.layout-container .el-header .el-dropdown__box:active,
.layout-container .el-header .el-dropdown__box:focus {
  outline: none;
}

.layout-container .el-main {
  padding: 0;
}

.search-container {
  display: flex;
  align-items: center;
  margin-left: 5%;
  margin-bottom: 20px;
}

.el-table {
  border: 1px solid #dcdfe6; /* 外框线 */
  margin-left: 5%;
}

.el-table th,
.el-table td {
  border: true; /* 内框线 */
}
</style>
