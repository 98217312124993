<template>
  <div class="app">
    <NavComponent />
    <div class="main">
      <Rewards />
    </div>
  </div>
</template>

<script>
import NavComponent from "@/components/AppNav.vue";
import Rewards from "@/components/Rewards.vue";

export default {
  components: {
    NavComponent,
    Rewards,
  },
};
</script>

<style scoped>
.main {
  width: 60%;
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
}
</style>
