<template>
  <section>
    <div class="box">
      <div class="square" style="--i: 0"></div>
      <div class="square" style="--i: 1"></div>
      <div class="square" style="--i: 2"></div>
      <div class="square" style="--i: 3"></div>
      <div class="square" style="--i: 4"></div>
      <div class="container">
        <div class="form">
          <h2>欢迎注册</h2>
          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
          >
            <div class="inputBox">
              <el-form-item label="手机号" prop="phone">
                <el-input
                  type="text"
                  v-model="ruleForm.phone"
                  placeholder="请输入手机号"
                  required
                ></el-input>
              </el-form-item>
            </div>
            <div class="inputBox">
              <el-form-item label="密码" prop="password">
                <el-input
                  type="password"
                  v-model="ruleForm.password"
                  placeholder="请输入密码"
                  required
                ></el-input>
              </el-form-item>
            </div>
            <div class="inputBox">
              <el-form-item label="确认密码" prop="confirmPassword">
                <el-input
                  type="password"
                  v-model="ruleForm.confirmPassword"
                  placeholder="请再次输入密码"
                  required
                ></el-input>
              </el-form-item>
            </div>
            <div class="inputBox">
              <el-form-item label="真实姓名" prop="trueName">
                <el-input
                  v-model="ruleForm.trueName"
                  placeholder="请输入真实姓名"
                  required
                ></el-input>
              </el-form-item>
            </div>
            <div class="inputBox">
              <el-form-item label="昵称" prop="name">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入昵称"
                  required
                ></el-input>
              </el-form-item>
            </div>
            <div class="inputBox">
              <el-form-item label="地址" prop="address">
                <el-input
                  type="textarea"
                  v-model="ruleForm.address"
                  placeholder="请输入地址"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  required
                ></el-input>
              </el-form-item>
            </div>
            <div class="inputBox">
              <el-button type="primary" @click="submitForm('ruleForm')"
                >注册</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios"; // 引入axios
import { getCurrentInstance } from "vue";
export default {
  data() {
    return {
      ruleForm: {
        phone: "",
        password: "",
        confirmPassword: "",
        trueName: "",
        name: "",
        address: "",
        instance: getCurrentInstance(),
      },
      isPhoneAvailable: true, // 用于跟踪手机号是否可用
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: this.checkPhoneNumber, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "密码长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: this.validateConfirmPassword, trigger: "blur" },
        ],
        trueName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
      },
    };
  },
  methods: {
    checkPhoneAvailability(rule, value, callback) {
      if (!value) {
        callback(new Error("手机号不能为空"));
      }
    },
    checkPhoneNumber(rule, value, callback) {
      const reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    },
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    },
    submitForm(formName) {
      // const that = this;
      this.$refs[formName].validate((valid) => {
        if (valid && this.isPhoneAvailable) {
          const formData = {
            phone: this.ruleForm.phone,
            password: this.ruleForm.password,
            nickName: this.ruleForm.name,
            name: this.ruleForm.trueName,
            address: this.ruleForm.address,
          };
          axios
            .post("http://116.205.107.96:7770/user/user/register", formData)
            .then((response) => {
              // 检查后端返回的code是否为200
              if (response.data.code === 200) {
                console.log("Success:", response.data);
                localStorage.setItem("token", response.data.data);
                const token = localStorage.getItem("token");
                console.log("注册成功获得token:" + token);
                alert("注册成功！");
                this.$router.push("/home");
              } else {
                // 如果code不为200
                console.error("Registration failed:", response.data);
                alert("注册失败：" + response.data.msg);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              alert("注册失败，请重试。");
            });
        } else if (!this.isPhoneAvailable) {
          alert("手机号已被注册，请使用其他手机号。");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;

  padding: 0;

  box-sizing: border-box;

  font-family: "Poppins", sans-serif;
}

body {
  overflow: hidden;
}

section {
  display: flex;

  justify-content: center;

  align-items: center;

  min-height: 100vh;
}

.box {
  position: relative;
}

.box .square {
  position: absolute;

  backdrop-filter: blur(5px);

  box-shadow: 0 25px 45px rgb(0, 0, 0, 0.1);

  border: 1px solid rgb(255, 255, 255, 0.5);

  border-right: 1px solid rgb(255, 255, 255, 0.2);

  border-bottom: 1px solid rgb(255, 255, 255, 0.2);

  background: rgb(255, 255, 255, 0.1);

  border-radius: 10px;

  animation: animate 10s linear infinite;

  animation-delay: calc(-1s * var(--i));
}

/*      */

@keyframes animate {
  0%,
  100% {
    transform: translateY(-40px);
  }

  50% {
    transform: translate(40px);
  }
}

.box .square:nth-child(1) {
  top: -50px;

  right: -60px;

  width: 100px;

  height: 100px;
}

.box .square:nth-child(2) {
  top: 150px;

  left: -100px;

  width: 120px;

  height: 120px;

  z-index: 2;
}

.box .square:nth-child(3) {
  bottom: 50px;

  right: -60px;

  width: 80px;

  height: 80px;

  z-index: 2;
}

.box .square:nth-child(4) {
  bottom: -80px;

  left: 100px;

  width: 50px;

  height: 50px;
}

.box .square:nth-child(5) {
  top: -90px;

  left: 140px;

  width: 60px;

  height: 60px;
}

.container {
  position: relative;

  width: 400px;

  min-height: 400px;

  background: rgb(255, 255, 255, 0.1);

  border-radius: 10px;

  display: flex;

  justify-content: center;

  align-items: center;

  backdrop-filter: blur(5px);

  box-shadow: 0 25px 45px rgb(0, 0, 0, 0.1);

  border: 1px solid rgb(255, 255, 255, 0.5);

  border-right: 1px solid rgb(255, 255, 255, 0.2);

  border-bottom: 1px solid rgb(255, 255, 255, 0.2);
}

.form {
  position: relative;

  height: 100%;

  width: 100%;

  padding: 40px;
}

.form h2 {
  position: relative;

  color: #fff;

  font-size: 24px;

  font-weight: 600;

  letter-spacing: 1px;

  margin-bottom: 40px;
}

.form h2::before {
  content: "";

  position: absolute;

  left: 0;

  bottom: -10px;

  width: 80px;

  height: 4px;

  background: #fff;
}

.form.inputBox {
  width: 100%;

  margin-top: 20px;
}

.form .inputBox .el-form-item__label {
  text-align: right;
}

.form .inputBox.el-input {
  width: 100%;

  background: rgb(255, 255, 255, 0.2);

  border: none;

  outline: none;

  padding: 10px 20px;

  border-radius: 35px;

  border: 1px solid rgb(255, 255, 255, 0.5);

  border-right: 1px solid rgb(255, 255, 255, 0.2);

  border-bottom: 1px solid rgb(255, 255, 255, 0.2);

  font-size: 16px;

  letter-spacing: 1px;

  color: #fff;

  box-shadow: 0 5px 15px rgb(0, 0, 0, 0.05);
}

.form .inputBox.el-input::placeholder {
  color: #fff;
}

.el-button {
  background: #fff;
  border-color: transparent;
  /* 去除边框颜色 */

  color: #666;

  max-width: 100px;

  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;

  font-weight: 600;
}

.forget {
  margin-top: 5px;

  color: #306d66;
}

.forget a {
  color: #306d66;

  font-weight: 600;
}
</style>
