<template>
  <div class="container">
    <div class="header">
      <button class="back-button" @click="goBack">返回</button>
    </div>
    <div class="match-results">
      <img src="@/assets/pic/logo1.png" alt="sale" class="logo" />
      <h4 class="game">{{ gameName }}</h4>
    </div>

    <div class="contestants">
      <h2 class="title">参赛选手</h2>
      <div
        v-for="contestant in contestants"
        :key="contestant.id"
        class="contestant"
      >
        <div class="contestant-info">
          <img :src="contestant.flagUrl" alt="" class="flag" />
          <span>{{ contestant.name }}</span>
        </div>
        <button
          :disabled="hasVoted"
          @click="vote(contestant.id)"
          :class="{
            voted: votedContestant === contestant.id,
            'disabled-button': hasVoted,
          }"
        >
          <span v-if="votedContestant === contestant.id">已投票</span>
          <span v-else>投票</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const token = localStorage.getItem("token");
    const datetime = proxy.$date;
    const route = useRoute();
    const router = useRouter();
    const gameName = ref("");
    const contestants = ref([]);
    const hasVoted = ref(false);
    const votedContestant = ref("");

    // 从 route.query 中获取 competitionId
    const competitionId = ref(route.query.id);

    onMounted(async () => {
      console.log("route.query:", route.query);
      console.log("route.query.id:", route.query.id);
      console.log("competitionId:", competitionId); // 确认 competitionId 是否正确获取
      try {
        const response = await fetch(
          `http://116.205.107.96:7770/user/game/person/${competitionId.value}`,
          {
            headers: { token: token },
          }
        );
        const result = await response.json();

        gameName.value = result.data.name;
        contestants.value = result.data.athletes.map((athlete) => ({
          id: athlete.id,
          name: athlete.name,
          flagUrl: `https://gstatic.olympics.com/s1/t_original/static/noc/oly/3x2/180x120/${athlete.countryCode}.png`,
        }));

        hasVoted.value = result.data.isVoted;
        votedContestant.value = result.data.votedId || "";
      } catch (error) {
        console.error("获取数据失败:", error);
      }
    });

    function formatDateTime(date) {
      const year = date.getFullYear(); // 获取年份
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 获取月份，月份从0开始，所以加1
      const day = date.getDate().toString().padStart(2, "0"); // 获取日期
      const hours = date.getHours().toString().padStart(2, "0"); // 获取小时
      const minutes = date.getMinutes().toString().padStart(2, "0"); // 获取分钟
      const seconds = date.getSeconds().toString().padStart(2, "0"); // 获取秒

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // 返回格式化的字符串
    }

    const vote = async (contestantId) => {
      if (hasVoted.value) return;

      const currentTime = formatDateTime(datetime);

      try {
        const response = await fetch(
          `http://116.205.107.96:7770/user/game/vote`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
            body: JSON.stringify({
              competitionId: competitionId.value,
              objectId: Number(contestantId),
              currentTime,
            }),
          }
        );

        const result = await response.json();
        if (result.code == 200) {
          votedContestant.value = contestantId;
          hasVoted.value = true;
        } else {
          console.error("投票失败:", result.msg);
        }
      } catch (error) {
        console.error("投票请求失败:", error);
      }
    };

    const goBack = () => {
      router.back();
    };

    return {
      gameName,
      contestants,
      hasVoted,
      votedContestant,
      vote,
      goBack,
    };
  },
};
</script>

<style scoped>
.container {
  background: #ffffffca;
  position: relative;
  min-height: 130vh;
}

.header {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 10px;
  z-index: 1000;
}

.back-button {
  font-size: 10px;
  padding: 10px 20px;
  margin-top: 30px;
  cursor: pointer;
  background-color: #f2f2f2d0;
  color: rgba(0, 0, 0, 0.568);
  width: 80px;
  height: 30px;
  border-radius: 10px;
  border: none;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #ddd;
}

.match-results {
  font-family: Arial, sans-serif;
  background-color: #7a5bb6;
  height: 280px;
  margin-top: -100px;
}

.logo {
  display: inline-block;
  width: 90px;
  height: auto;
  margin-top: 40px;
}

.game {
  font-size: 28px;
  color: #ffffffda;
  font-weight: bold;
  margin-top: 20px;
}

.title {
  margin-bottom: 30px;
}

.contestants {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contestant {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 70px;
  max-width: 600px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
}

.contestant-info {
  display: flex;
  align-items: center;
}

.flag {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

button {
  margin-left: 10px;
  width: 60px;
  height: 30px;
  border: none;
  border-radius: 10px;
  background-color: #c08fca;
  color: white;
  position: relative; /* 对按钮进行相对定位 */
}

button.voted {
  background-color: grey;
  color: white;
}

button.disabled-button {
  cursor: not-allowed; /* 设置为禁止点击状态的光标 */
}
</style>
