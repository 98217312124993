<template>
  <div class="app">
    <NavComponent />

  <header>
    <div class="section__container header__container">
      <div class="header__content">
        <p>Olympic Games & 2024 Paris</p>
        <h1>Olympic Games &<br>2024 Paris</h1>
        <button class="btn" @click="navigateToSchedule">点击竞猜</button>
      </div>
      <div class="header__image-overlay">
        <div class="header__image">
          <img src="@/assets/pic/zafett.png" alt="header" />
        </div>
      </div>
    </div>
  </header>


  <section class="section__container musthave__container">
    <h2 class="section__title">奥运精彩瞬间</h2>
    <div class="musthave__nav">
    <div class="carousel-container">
      <el-carousel :interval="4000" type="card" height="400px">
        <el-carousel-item v-for="(item, index) in images" :key="index">
        <img :src="item" alt="carousel image" class="carousel-image" />
      </el-carousel-item>
    </el-carousel>
  </div>
    </div>
  </section>


  <section class="section__container sale__container">
    <h2 class="section__title">更多内容</h2>
    <div class="sale__grid">
      <div class="sale__card">
        <img src="@/assets/pic/dc.jpg" alt="sale" />
        <div class="sale__contentb">
          <p class="sale__subtitle">- SCHEDULE -</p>
          <h4 class="sale__title"> <span>比赛竞猜</span> </h4>
          <p class="sale__subtitle">- SCHEDULE -</p>
          <button class="btn sale__btn"  @click="navigateToSchedule">查看详情</button>
        </div>
      </div>
      <div class="sale__card">
        <img src="@/assets/pic/prize.png" alt="sale" />
        <div class="sale__content">
          <p class="sale__subtitle">- Prize -</p>
          <h4 class="sale__title"> <span>奖品兑换</span> </h4>
          <p class="sale__subtitle">- Prize -</p>
          <button class="btn sale__btn" @click="navigateToGift">查看详情</button>
        </div>
      </div>
      <div class="sale__card">
        <img src="@/assets/pic/da.jpg" alt="sale" />
        <div class="sale__contentb">
          <p class="sale__subtitle">- MyInfo -</p>
          <h4 class="sale__title"> <span>我的信息</span> </h4>
          <p class="sale__subtitle">- MyInfo -</p>
          <button class="btn sale__btn" @click="navigateToMyInfo">查看详情</button>
        </div>
      </div>
    </div>
  </section>



  <hr />
</div>
</template>


<script>
import image1 from '@/assets/pic/lbt1.jpg';
import image2 from '@/assets/pic/lbt2.jpg';
import image3 from '@/assets/pic/lbt3.jpg';
import image4 from '@/assets/pic/lbt4.jpg';


import { ElCarousel, ElCarouselItem } from 'element-plus';
import NavComponent from '@/components/AppNav.vue';
import { useRouter } from 'vue-router';

export default {
name: 'App',
components: {
  ElCarousel,
  ElCarouselItem,
  NavComponent,
},
setup() {
    const router = useRouter();
    
    const navigateToGift = () => {
      router.push('/gift');
    };

    const navigateToMyInfo = () => {
      router.push('/myinfo');
    };

    const navigateToSchedule = () => {
      router.push('/schedule');
    };

    const navigateToMoreSection = () => {
      router.push('/more');
    };

    return {
      navigateToGift,
      navigateToMyInfo,
      navigateToSchedule,
      navigateToMoreSection,
    };
  },
data() {
  return {
    images: [
      image3,
      image1,
      image2,
      image4,
    ],
  };
},
};
</script>

<style>
@import '~@/assets/homestyles.css';

.text-box {
width: 40%;
padding: 20px;
background-color: rgba(255, 255, 255, 0.7); /* Transparent box */
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
border-radius: 10px;
text-align: center;
}

.carousel-container {
width: 100%;
z-index: 10000;
}

.carousel-image {
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 10px;
}
</style>